<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="
        showPreloader ||
        $store.state.refresh.fetchingNewData ||
        $store.state.refresh.waiting_filter
      "
      style="height: 90vh"
    >
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
      <table-fast-addresses
        :dataTable="fastAddresses"
        @editForm="editForm"
        @refresh="refresh"
      />
    </div>
    <modal-fast-addresses @refresh="refresh" :synonyms="synonyms" />
    <modal-fast-addresses-edit
      :id="id"
      @refresh="refresh"
      @sendSynonyms="sendSynonyms"
      :changesSynonyms="changesSynonyms"
    />
    <modal-synonyms @addSynonyms="addSynonyms"></modal-synonyms>
    <modal-synonyms-edit
      :editSynonyms="editSynonyms"
      @changeSynonyms="changeSynonyms"
    ></modal-synonyms-edit>
    <filter-modalVue
      :fields="fields"
      @sendToParent="sendToParent"
    ></filter-modalVue>
  </div>
</template>
<script>
import tableFastAddresses from "@/views/component/Table/tableFastAddresses.vue";
import modalFastAddresses from "@/views/component/Modal/ModalFastAddresses/modalFastAddresses.vue";
import modalFastAddressesEdit from "@/views/component/Modal/ModalFastAddresses/modalFastAddressesEdit.vue";
import modalSynonyms from "@/views/component/Modal/ModalFastAddresses/modalSynonyms.vue";
import modalSynonymsEdit from "@/views/component/Modal/ModalFastAddresses/modalSynonymsEdit.vue";
export default {
  components: {
    tableFastAddresses,
    modalFastAddresses,
    modalFastAddressesEdit,
    modalSynonyms,
    modalSynonymsEdit,
  },
  data() {
    return {
      id: "",
      fastAddresses: [],
      editSynonyms: [],
      synonyms: [],
      changesSynonyms: [],
      showPreloader: false,
      fields: [
        { key: "id", label: "Id" },
        { key: "name", label: "Наименование" },
        { key: "fast_address_category", label: "Наименование" },
        { key: "addres_street", label: "Улица" },
        { key: "street_type", label: "Тип улицы" },
        { key: "district", label: "Район" },
        { key: "village", label: "Населённый пункт" },
        { key: "city", label: "Город" },
        { key: "region", label: "Регион" },
        { key: "synonym", label: "Синонимы" },
        { key: "is_active", label: "Статус" },
      ],
    };
  },
  mounted() {
    this.$store.commit("pageData/setdataCount", null);
    this.openFilter();
  },
  methods: {
    editForm(id) {
      this.id = id;
      setTimeout(this.openModal, 0);
    },
    openFilter() {
      let allRoutes = this.$store.state.draggableTab.tabs;
      let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      let arrayRoutes = [];
      allRoutes.forEach((element) => {
        arrayRoutes.push(element.path);
      });
      if (arrayRoutes.includes(this.$route.path) == true) {
        this.showPreloader = true;
        this.$http.get(`${this.$route.name}`, { params: param }).then((res) => {
          this.fastAddresses = res.data;
          this.$store.commit(
            "pageData/setdataCount",
            this.fastAddresses.length
          );
          this.showPreloader = false;
          this.$store.commit("REFRESH_DATA", false);
        });
      } else if (arrayRoutes.includes(this.$route.path) == false) {
        this.$bvModal.show(this.$route.name + "filter");
      }
    },
    openModal() {
      this.$bvModal.show("fastAddressesModalEdit");
    },
    refresh() {
      this.showPreloader = true;
      this.$http.get("addresses/fast/addresses").then((res) => {
        this.fastAddresses = res.data;
        this.$store.commit("pageData/setdataCount", this.fastAddresses.length);
        this.showPreloader = false;
        this.$store.commit("REFRESH_DATA", false);
      });
    },
    sendToParent(tableData) {
      this.fastAddresses = tableData;
      this.$store.commit("pageData/setdataCount", this.fastAddresses.length);
    },
    addSynonyms(synonyms) {
      this.synonyms = synonyms;
    },
    sendSynonyms(editSynonyms) {
      this.editSynonyms = editSynonyms;
    },
    changeSynonyms(changesSynonyms) {
      this.changesSynonyms = changesSynonyms;
    },
  },
  computed: {
    fetchingNewData() {
      return this.$store.state.refresh.fetchingNewData;
    },
  },
  watch: {
    fetchingNewData(val) {
      if (val) {
        this.$http.get(`${this.$route.name}`).then((res) => {
          this.fastAddresses = res.data;
          this.$store.commit(
            "pageData/setdataCount",
            this.fastAddresses.length
          );
          this.$store.commit("REFRESH_DATA", false);
        });
      }
    },
  },
};
</script>
