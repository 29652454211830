import { resizeable } from "@/utils/TableFunctions/resizeable.js";
export default {
  data() {
    return {
      allData: [],
      displayedData: [],
      isLoading: false,
      currentLimit: 300,
    };
  },
  mounted() {
    this.allData = this.dataTable;
    this.displayedData = this.allData.slice(0, this.currentLimit);
    resizeable();
  },
  methods: {
    handleScroll(event) {
      const bottomReached = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + 30;
      if (bottomReached && !this.isLoading) {
        this.loadMoreData();
      }
    },
    loadMoreData() {
      this.isLoading = true;
      const nextLimit = this.currentLimit + 150;

      setTimeout(() => {
        if (nextLimit >= this.allData.length) {
          this.displayedData = this.allData.slice(0, this.allData.length);
        } else {
          this.displayedData = this.allData.slice(0, nextLimit);
        }

        this.currentLimit = Math.min(nextLimit, this.allData.length);
        this.isLoading = false;
      }, 0);
    },
  },
};
